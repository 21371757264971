import React from 'react';

const Services = () => {
  return (
      <section id="page5" className='page1'>
        <div>
          <img src='/5.png' alt='3.png' className='page5-image'/>
        </div>
      </section>
  );
};

export default Services;
