import React from 'react';

const About = () => {
  return (
    <section id="page3" className='page1'>
      <div>
        <img src='/3.png' alt='3.png' className='page3-image'/>
      </div>
    </section>
  );
};

export default About;
